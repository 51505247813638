import React from 'react'
import Moment from 'moment'
import Grid from '@material-ui/core/Grid'
import './style.css'
import DailyReminder from './dailyReminder'
import SalahCard from './salahCard'
import UpcomingEvent from './upcomingEvent'
import { connect } from 'react-redux'
import { ApiContext } from "../contextAPIS/context";
import { withRouter } from 'react-router-dom'
import { markdowntoreact } from '../../util/markdown'
import { isHeader } from '../../store/users/actions'
import { setSteps } from '../../store/payment/actions'
import { persistStore } from 'redux-persist';


import { isDeviceVerified } from '../../screens/OnBoardingProcess/Apis'
// import WebSocket from 'isomorphic-ws';
import {
  updateSalah,
  updateReminders,
  updateEvents,
  updateAnnoucements,
  updateServices,
} from "../../store/users/actions";
class HomePageCards extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      hour: 0,
      minutes: 0,
      salahResponse: [],
      dailyReminders: [],
      currentSalah: [],
      nextSalahs: [],
      salahData: [],
      eventResponse: [],
      isReset: true,
      salah: [],
      update: false,
      stoptimer: false
    }
  }

  async checkStatus() {
    const deviceId = localStorage.getItem('deviceId')
    const checkVerification = await isDeviceVerified(deviceId)
    const checkStatus = checkVerification?.data?.body?.data?.device
    if (checkStatus?.status === 'Pause') {
      this.props.isHeader(false)
      this.props.history.push("/pause")
    }
    else {
      this.statusInterval = setInterval(async () => {
        const deviceId = localStorage.getItem('deviceId')
        const checkVerification = await isDeviceVerified(deviceId)
        const checkStatus = checkVerification?.data?.body?.data?.device
        if (checkStatus?.status === 'Pause') {
          this.props.isHeader(false)
          this.props.history.push("/pause")
        }
      }, 10000)
    }
  }

  componentDidMount() {
    this.checkStatus()
    this.realTime()
    this.props.isHeader(true)
    this.props.setSteps(1)
    this.renderSalah(this.props.salah)
  }

  realTime() {
    const sharedEnv = {
      prod: process.env.REACT_APP_PROD_SOCKET_URL,
      dev: process.env.REACT_APP_DEV_SOCKET_URL
    }
    let currentEnv
    if (process.env.REACT_APP_Project_Environment === 'localhost' || process.env.REACT_APP_Project_Environment === 'dev') {
      currentEnv = 'dev'
    } else {
      currentEnv = 'prod'
    }
    const socketURL = sharedEnv[currentEnv]
    const { orgId } = this.props;
    function makeConnection(orgId) {
      let websocket;
      if (orgId && socketURL) {
        const WS_URL = `${socketURL}?orgId=${orgId}`;
        websocket = new WebSocket(WS_URL);
      }
      return websocket
    }
    let websocket = makeConnection(orgId)
    websocket.onclose = function (event) {
      websocket = makeConnection()
      console.log('onclose', event)
    };
    websocket.onmessage = ((data) => {
      const { announcement, reminders, salah, events } = this.props;
      const newData = JSON.parse(data?.data)
      const message = newData?.notify
      if (!message) return
      if (message === 'add annoucement') {
        if (announcement) {
          const new_announcements = [...announcement, newData.data]

          this.props.updateAnnoucements(new_announcements)
        }
      }
      if (message === 'update annoucement') {
        if (announcement) {
          const updateAnnouncement = announcement.filter((el) => el._id !== newData?.data._id)
          this.props.updateAnnoucements([...updateAnnouncement, newData.data])
        }
      }
      if (message === 'delete annoucement') {
        if (announcement) {
          console.log('announcements', announcement)
          const deleteAnnouncement = announcement.filter((el) => el._id !== newData?.data.split(" ")[3])
          this.props.updateAnnoucements(deleteAnnouncement?.length > 0 ? deleteAnnouncement : [])

        }
      }
      if (message === 'add reminder') {
        if (reminders) {
          const newReminders = [...reminders, newData.data]
          this.props.updateReminders(newReminders)
        }
      }
      if (message === 'update reminder') {
        if (reminders) {
          const newReminders = reminders.filter((el) => el._id !== newData?.data._id)
          this.props.updateReminders([...newReminders, newData.data])
        }
      }
      if (message === 'delete reminder') {
        if (reminders) {
          const deletereminders = reminders.filter((el) => el._id !== newData?.data.split(" ")[3])
          this.props.updateReminders(deletereminders?.length > 0 ? deletereminders : [])

        }
      }
      if (message === 'add salah') {
        if (salah) {
          let tempsalah = [...salah];
          tempsalah.push(newData.data)
          console.log('tempsalah', tempsalah)
          this.props.updateSalah(tempsalah)
        }
      }
      if (message === 'update salah') {
        if (salah) {
          const newSalah = salah.filter((el) => el._id !== newData?.data._id)
          this.props.updateSalah([...newSalah, newData.data])
          this.setState({ update: true })
        }
      }
      if (message === 'delete salah') {
        if (salah) {
          const deletesalah = salah.filter((item) => item._id !== newData?.data._id)
          this.props.updateSalah(deletesalah?.length > 0 ? deletesalah : [])
          console.log('deletesalah', deletesalah)
          if (deletesalah.length === 0) {
            clearInterval(this.interval)
            this.setState({ hour: 0, minutes: 0 })
          }
        }
      }
      if (message === 'add event') {
        if (events) {
          let tempevent = [...events];
          tempevent.push(newData.data)
          this.props.updateEvents(tempevent)
        }
      }
      if (message === 'update event') {
        if (events) {
          const newEvent = events.filter((el) => el._id !== newData?.data._id)
          this.props.updateEvents([...newEvent, newData.data])
        }
      }
      if (message === 'delete event') {
        if (events) {
          const deleteEvent = events.filter((el) => el._id !== newData?.data.split(" ")[3])
          this.props.updateEvents(deleteEvent?.length > 0 ? deleteEvent : [])

        }
      }
    });
  }

  timer(nextSalah) {
    let eventTime = nextSalah
    let currentTime = this.convertToMiliseconds(new Date())
    if (eventTime && currentTime) {
      let difference = eventTime - currentTime;
      let hours = 0;
      let minutes = 0;
      let getDuration = 0
      if (Math.sign(difference) === -1) {
        const et = new Date()
        const ct = new Date()
        let eventhours = Math.floor(eventTime / 1000 / 60 / 60);
        let currenthours = Math.floor(currentTime / 1000 / 60 / 60);
        let eventmin = Math.floor((eventTime / 1000 / 60 / 60 - eventhours) * 60);
        let currentmin = Math.floor((currentTime / 1000 / 60 / 60 - currenthours) * 60);
        et.setHours(eventhours, 0)
        ct.setHours(currenthours, 0)
        et.setDate(new Date().getDate() + 1)
        hours = Moment(et).diff(Moment(ct), 'hours')
        minutes = 60 - currentmin
      }
      else {
        getDuration = eventTime - currentTime;
        hours = Math.floor(getDuration / 1000 / 60 / 60);
        minutes = Math.floor((getDuration / 1000 / 60 / 60 - hours) * 60);
      }
      this.setState({ hour: hours, minutes: minutes })
    }
  }
  getCountDown(nextSalah) {
    this.timer(nextSalah);
    this.interval = setInterval(() => {
      if (this.state.minutes > 0) {
        this.setState({ minutes: this.state.minutes - 1 });
      }
      if (this.state.minutes === 0) {
        if (this.state.hour === 0) {
          clearInterval(this.interval)
          this.renderSalah(this.props.salah)
        } else {
          this.setState({ hour: this.state.hour - 1 });
          this.setState({ minutes: 59 });
        }
      }
    }, 60000)
  }

  convertToMiliseconds(value, date) {
    console.log(date, value)
    const get24Hours = Moment(value).format("HH:mm")
    console.log('get24Hours', get24Hours)

    const hours = get24Hours.split(":")[0]
    const minutes = get24Hours.split(":")[1]
    return ((hours * 3600000) + (minutes * 60000))
  }

  componentWillUnmount() {
    clearInterval(this.interval)
    clearInterval(this.statusInterval)
  }
  renderSalah(salah) {
    const filtered = salah && salah.length > 0 && salah.filter((item) => item.waqt && item.active)
    const data = filtered && filtered.map((item) => {
      return {
        salahName: item.salahName,
        nextsalah: new Date(item.iqamahTime).toTimeString() >= new Date().toTimeString(),
        waqt: item.waqt,
        salahtime: item.iqamahTime,
        iqamahtimestamp: this.convertToMiliseconds(item.iqamahTime)
      }
    })
    console.log('data', data)
    const getLeastTimeSalah = data && data.sort((a, b) => a.iqamahtimestamp - b.iqamahtimestamp)
    const getSortedLeastSalah = getLeastTimeSalah && getLeastTimeSalah.length > 0 && getLeastTimeSalah.sort((x, y) => y.nextsalah - x.nextsalah)
    getSortedLeastSalah && this.getCountDown(getSortedLeastSalah[0]?.iqamahtimestamp)
    this.setState({ nextSalahs: getSortedLeastSalah, update: false })
  }
  componentDidUpdate(prevProps) {

    if (prevProps.salah?.length !== this.props.salah?.length || this.state.update) {
      this.renderSalah(this.props.salah)
    }
  }
  render() {
    const { currentSalah, nextSalahs } = this.state
    const { events, reminders, salah } = this.props
    const reminderDescription = reminders && reminders.filter((item) => item.active).map((item) => {
      if (item.active) {
        return markdowntoreact(JSON.parse(item.description));
      }
    })
    const eventResponse = events ? events.length > 0 && events.filter((item) => item.published) : []

    return (
      <div style={{ flexGrow: 1 }}>

        <div className='space'>
          <Grid container spacing={3}>

            <Grid item lg={4} style={{ height: '59vh' }}>
              <DailyReminder
                quote={reminderDescription} bookName='- Al Quran'
              />
              {/* {dailyReminders.map((item) => {
                return item.frequency === 'daily' ?  <DailyReminder
                quote={item.description} bookName='- Al Quran'
              /> : 'null'
              })} */}
            </Grid>

            <Grid item lg={4} style={{ height: '59vh' }}>
              <SalahCard
                hours={this.state.hour}
                minutes={this.state.minutes}
                // currentSalah={currentSalah?.SalahName} currentSalahTime={currentSalah?.SalahTime && Moment(currentSalah?.SalahTime).format("hh:mm A")}
                nextSalah={nextSalahs}
              />
            </Grid>

            <Grid item lg={4} style={{ height: '59vh' }}>
              <UpcomingEvent eventResponse={eventResponse} />
            </Grid>
          </Grid>

        </div>

      </div>
    )
  }

}


function mapStateToProps(state) {
  return {
    orgId: state.users.orgId,
    token: state.users.token,
    salah: state.users.salah,
    reminders: state.users.reminders,
    events: state.users.events,
    announcement: state.users.annoucements
  }
}
function mapDispatchToProps(dispatch) {
  return {
    isHeader: (boolean) => dispatch(isHeader(boolean)),
    updateAnnoucements: (annoucementdata) => dispatch(updateAnnoucements(annoucementdata)),
    updateSalah: (salahdata) => dispatch(updateSalah(salahdata)),
    updateReminders: (remindersdata) => dispatch(updateReminders(remindersdata)),
    updateEvents: (eventsdata) => dispatch(updateEvents(eventsdata)),
    updateServices: (servicesdata) => dispatch(updateServices(servicesdata)),
    setSteps: (value) => dispatch(setSteps(value)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HomePageCards))

