const INITIAL_STATE = {
  
  donationType: 'Monthly',
  fee: false,
  category: '',
  categoryId: '',
  note: '',
  ishome: true,
  isHideButton : true,
  step : 1,
  categories : [],
  edit : false,
  amount: 50,
  total : 0,
  isamount : false,
  stripeamount : 50,
  show_ToS : {bool : false , index : 0}
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_AMOUNT': {
      return { ...state, amount: action.payload }
    }
    case 'SET_DONATIONTYPE': {
      return { ...state, donationType: action.payload }
    }
    case 'SET_FEE': {
      return { ...state, fee: action.payload }
    }
    case 'SET_CATEGORY': {
      return { ...state, category: action.payload, categoryId: action.id }
    }
    case 'SET_NOTE': {
      return { ...state, note: action.payload }
    }
    case 'SET_HOMEPAGE' : {
      return { ...state, ishome: action.payload }
    }
    case 'SET_HIDE_BUTTON' : {
      return {...state , isHideButton : action.payload}
    }
    case 'SET_STEP' : {
      return {...state , step : action.payload}
    }
    case 'SET_CATEGORIES' : {
      return {...state , categories : action.payload}
    }
    case 'SET_EDIT' : {
      return {...state , edit : action.payload}
    }
    case 'SET_TOTAL' : {
      return {...state , total : action.payload}
    }
    case 'SET_OTHERVAL' : {
      return {...state , isamount : action.payload}
    }
    case 'SET_STRIP_AMOUNT' : {
      return {...state , stripeamount : action.payload}
    }
    case 'SET_ToS' : {
      return {...state , show_ToS : action.payload}
    }
    
    case 'SET_CLEAR' : {
      return {...INITIAL_STATE}
    }
    
  
    default:
      return state
  }
}
