import axios from 'axios'

const env = {
  prod: process.env.REACT_APP_PAYMENT_PROD_API,
  dev: process.env.REACT_APP_PAYMENT_DEV_API
}

const sharedEnv = {
  prod: process.env.REACT_APP_BASEURL_PROD_API,
  dev: process.env.REACT_APP_BASEURL_DEV_API
}
let currentEnv
if (process.env.REACT_APP_Project_Environment === 'localhost' || process.env.REACT_APP_Project_Environment === 'dev') {
  currentEnv = 'dev'
} else {
  currentEnv = 'prod'
}
const paymentBaseUrl = env[currentEnv]
const orgBaseUrl = sharedEnv[currentEnv]

export function login (user) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${paymentBaseUrl}/auth/authenticate`, user)
      .then((res) => {
        resolve(res)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export function stripTokenGet (cardInfo, chargeInfo) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${paymentBaseUrl}/stripe/token`, cardInfo)
      .then((res) => {
        if (res.data.body.data) {
          const { amount, orgId, phoneNo, description, frequency, categoryId,
            processingFee, memoNote, firstName, lastName, email,billing_details } = chargeInfo
          axios
            .post(`${paymentBaseUrl}/stripe/charges`, {
              amount: amount * 100,
              currency: 'USD',
              description,
              // source: '1',
              source: res.data.body.data,
              orgId,
              phoneNo,
              frequency,
              stream: 'DEENKIOSK',
              categoryId,
              processingFee,
              memoNote,
              firstName,
              lastName,
              email,
              billing_details
            })
            .then((res) => {
              resolve(res)
            })
            .catch((error) => {
              reject(error)
            })
        } else {
          resolve(res)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export function getCategories (orgId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/donation-category?orgId=${orgId}`)
      .then((res) => {
        resolve(res)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export function organizations (orgId , token) {
  console.log('orgId' ,orgId)
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/digital-device-bridge/organizations/${orgId}` , {
        headers: {
          "Content-Type": "application/json",
          "token":token
        },
      })
      .then((res) => {
        resolve(res)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export function salah (orgId , token){
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/digital-device-bridge/salah?orgId=${orgId}`,{
        headers: {
          "Content-Type": "application/json",
          "token":token
        },
      })
      .then((res) => {
        resolve(res)
      })
      .catch((error) => {
        reject({})
      })
  })
}

export function services (orgId , token) {
  console.log('orgId' ,orgId)
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/digital-device-bridge/services?orgId=${orgId}`,{
        headers: {
          "Content-Type": "application/json",
          "token":token
        },
      })
      .then((res) => {
        resolve(res)
      })
      .catch((error) => {
        reject([])
      })
  })
}

export function reminders (orgId , token) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/digital-device-bridge/reminders?orgId=${orgId}`,{
        headers: {
          "Content-Type": "application/json",
          "token":token
        },
      })
      .then((res) => {
        resolve(res)
      })
      .catch((error) => {
        reject({})
      })
  })
}

export function annoucements (orgId , token) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/digital-device-bridge/announcements?orgId=${orgId}`,{
        headers: {
          "Content-Type": "application/json",
          "token":token
        },
      })
      .then((res) => {
        resolve(res)
      })
      .catch((error) => {
        reject({})
      })
  })
}
export function upcomingEvents(token) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/digital-device-bridge/events` , {
        headers: {
          "Content-Type": "application/json",
          "token":token
        },
      })
      .then((res) => {
        resolve(res)
      })
      .catch((error) => {
        reject({})
      })
  })
}

export function contactform(formObject) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${orgBaseUrl}/contact` , formObject)
      .then((res) => {
        resolve(res)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

