export function updateAmount (amount) {
  return dispatch => dispatch({ type: 'SET_AMOUNT', payload: amount })
}
export function updateisAmount (boolean) {
  return dispatch => dispatch({ type: 'SET_OTHERVAL', payload: boolean })
}
export function updateDonationType (value) {
  return dispatch => dispatch({ type: 'SET_DONATIONTYPE', payload: value })
}

export function updateٖٖٖٖٖٖٖFee (value) {
  return dispatch => dispatch({ type: 'SET_FEE', payload: value })
}

export function categoryDonation (value, categoryId) {
  return dispatch => dispatch({ type: 'SET_CATEGORY', payload: value, id: categoryId })
}
export function addNote (value) {
  return dispatch => dispatch({ type: 'SET_NOTE', payload: value})
}
export function isHomepage (value) {
  return dispatch => dispatch({ type: 'SET_HOMEPAGE', payload: value})
}
export function isHideButton (value) {
  return dispatch => dispatch({ type: 'SET_HIDE_BUTTON', payload: value})
}
export function updateEDIT (value) {
  return dispatch => dispatch({ type: 'SET_EDIT', payload: value})
}
export function setSteps (value) {
  return dispatch => dispatch({ type: 'SET_STEP', payload: value})
}
export function saveCategories (value) {
  return dispatch => dispatch({ type: 'SET_CATEGORIES', payload: value})
}
export function updatetotalAmount (value) {
  return dispatch => dispatch({ type: 'SET_TOTAL', payload: value})
}
export function updateStripeAmount (value) {
  return dispatch => dispatch({ type: 'SET_STRIP_AMOUNT', payload: value})
}

export function showTos_PP(value) {
  return dispatch => dispatch({ type: 'SET_ToS', payload: value})
}

export function clearState () {
  return dispatch => dispatch({ type: 'SET_CLEAR'})
}