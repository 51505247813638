import axios from 'axios'

const sharedEnv = {
  prod: process.env.REACT_APP_BASEURL_PROD_API,
  dev: process.env.REACT_APP_BASEURL_DEV_API
}
let currentEnv
if (process.env.REACT_APP_Project_Environment === 'localhost' || process.env.REACT_APP_Project_Environment === 'dev') {
  currentEnv = 'dev'
} else {
  currentEnv = 'prod'
}
const orgBaseUrl = sharedEnv[currentEnv]

export function getOTP(deviceId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${orgBaseUrl}/digital-device-bridge/getOtpCode?deviceId=${deviceId}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject({})
        })
    })
  }


  export function isDeviceVerified(deviceId) {
    return new Promise((resolve,reject)=> {
      axios
          .get(`${orgBaseUrl}/digital-device-bridge/isDeviceVerified?deviceId=${deviceId}`)
          .then((res)=> {
            resolve(res)
          })
          .catch((error)=> {
            reject({error})
          })
    })
  }