import React from 'react'
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { connect } from 'react-redux'
import Button from '../../components/Button'
import './style.css'
import { contactform } from '../../components/Donation/apis/index'
import CircularProgress from '@material-ui/core/CircularProgress'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import '../../components/Donation/css/Donation.css'
import imgtick from '../../assets/rawimg/check.png'
import CheckIcon from '@material-ui/icons/Check';
import { withRouter } from 'react-router-dom'
import HomeRoundedIcon from '@material-ui/icons/HomeRounded'

String.prototype.capitalize = function () {
  var sa = this.replace(/-/g, ' ')
  var saa = sa.toLowerCase()
  var sb = saa.replace(/(^|\s)([a-z])/g, function (m, p1, p2) { return p1 + p2.toUpperCase() })
  var sc = sb.replace(/\s+/g, ' ')
  return sc
}

class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fullName: '',
      zipCode: '',
      email: '',
      phoneNumber: '',
      inquiryType: '',
      message: '',
      isError: false,
      isEmailError: false,
      isPhoneError: false,
      isSubmit: true,
      isNameError: false,
      dialCode: '',
      countryAlphaCode: '',
      countryCode: '',
      phoneError: '',
      isPhonenumberError: false,
      error: false,
      num: '',
      isZipError: false,
      showMessage: false,
      isfocus : false

    }
  }

  submitForm = async () => {
    const { fullName, zipCode, email, phoneNumber, inquiryType, message, isEmailError ,dialCode , error } = this.state;
    if (fullName && zipCode && phoneNumber.length > dialCode.length && email && error === false) {
      this.setState({ isSubmit: false })
      const orgId = this.props.orgId
      const deviceId = localStorage.getItem("deviceId")
      const contactObject = {
        firstName: fullName.split(" ")[0],
        lastName: fullName.split(" ")[1] ? fullName.split(" ")[1] : "",
        postal_code:zipCode,
        email: email,
        phone: phoneNumber,
        inquiry_type: inquiryType,
        message: message,
        orgId: orgId,
        stream: `DEENKIOSK/${deviceId}`,
      }
      try {
        const contact = await contactform(contactObject)
        if (contact.data.body.data) {
          this.setState({ isSubmit: true, showMessage: true })
          this.setState({ fullName: '', zipCode: '', email: '', phoneNumber: '', inquiry_type: 'Fund', message: '', dialCode: '+1' })
        }
      }
      catch (e) {
        this.setState({ isSubmit: true })
      }
    }
    else {
      this.setState({ isError: true, phoneError: 'Phone number required', error: true, isEmailError: true, isNameError: true, })
      if (fullName) {
        this.setState({ isNameError: false })
      }
      if (zipCode) {
        this.setState({ isError: false })

      }
      if (!isEmailError && email) {
        this.setState({ isEmailError: false })
      }
      if(phoneNumber.length > dialCode.length && error === false){
        this.setState({ phoneError: '' , error: false })
      }
      if (!this.state.phoneNumber || this.state.dialCode  === this.state.phoneNumber) {
        this.setState({ phoneError: 'Phone number is required' , error: true })

      }
    }

  }



  render() {
    const {
      isError,
      zipCode,
      isZipError,
      isNameError,
      email,
      phoneNumber,
      isEmailError,
      isPhoneError,
      fullName,
      error,
      isfocus,
      countryAlphaCode,
      isPhonenumberError,
      phoneError,
      showMessage,
      message
    } = this.state
    return (
      <div className='bg'>
        <div className='kiosk_container'>
            {!showMessage ? <div>
              <Grid container spacing={0} style={{ paddingTop: 0 }}>
                <Grid item lg={2}>
                <div className='btn_back_innrepage'>
                  <Link className='linkStyle' to='/home'>
                  <Button
                    title='GO BACK'
                      style={{ fontSize: '22px', backgroundColor: '#fff', color: '#7266FB', fontFamily: 'Roboto-Medium',}}
                      widthHeight={[270, 70]}
                      variant='outlined'
                      icon={<ArrowBackIcon />}
                    />
                  </Link>
                  </div>
                </Grid>
                <Grid item lg={8}>
                  <div className='page_link'>
                    <Link to='/home'>{<HomeRoundedIcon />} Home</Link>
                    <span><i className='fas fa-chevron-right' /></span>
                    <Link to='/contact'>Contact Us</Link>
                  </div>
                  <div className='center_section'>
                    <div className='about_form_heading'>Contact us</div>
                    <div className='about_form'>
                      <form className='embed_dontation_form'>

                        <div className='row1'>
                          <Grid container>
                            <Grid lg={8} style={{ paddingRight: 20 }}>
                              <div className='form-group input-group'>
                                <span className='border-lable-flt'>
                                  <input type='text' value={this.state.fullName}
                                    // onBlur={() => {
                                    //   const zip_Regex = /^[a-zA-Z]/
                                    //   zip_Regex.test(fullName) ? this.setState({ isNameError: false }) : this.setState({ isNameError: true })
                                    // }}
                                    // onFocus={() => this.setState({ isNameError: false })}
                                    onChange={(e) => {
                                      const nameRegx = /^[a-zA-Z\s\.]*$/
                                      if (e.target.value.length < 20 && e.target.value.match(nameRegx)) {
                                        this.setState({ fullName: e.target.value.capitalize(), isNameError: false })
                                      }
                                    }}
                                    className={`form-control ${isNameError && 'v_error'}`} id='label-name_1' placeholder='Full name' required />
                                  <label for='label-name_1'>Full name</label>
                                </span>

                              </div>
                              {isNameError && <span className="tel_error_msg"
                                style={{
                                  display: 'flex',
                                  fontFamily: 'Roboto-Regular',
                                  letterSpacing: 0.4,
                                  color: 'red !important',
                                  fontSize: 16,
                                  textIndent: '26px',
                                  color: '#FF5353',
                                  paddingTop: 18,
                                  // marginLeft: 22,
                                  marginTop: -26,
                                  position: "absolute"
                                }}>Full name is required</span>}
                            </Grid>

                            <Grid lg={4}>
                              <div className='form-group input-group'>
                                <span className='border-lable-flt'>
                                  <input type='text' value={this.state.zipCode}
                                    // onFocus={() => this.setState({ isError: false })}

                                    onChange={(e) => {
                                      const zip_Regex = /^[a-zA-Z0-9]*$/
                                      // && e.target.value.match(zip_Regex)
                                      if (e.target.value.length <= 10 && e.target.value.match(zip_Regex) ) {
                                        this.setState({ zipCode: e.target.value.toUpperCase(), isError: false })
                                      }
                                    }}
                                    className={`form-control ${isError && 'v_error'}`} id='label-name_2' placeholder='1234' required />
                                  <label for='label-name_2'>Zip code</label>
                                </span>
                              </div>
                              {isError && <span className="tel_error_msg"
                                style={{
                                  display: 'flex',
                                  fontFamily: 'Roboto-Regular',
                                  letterSpacing: 0.4,
                                  color: 'red !important',
                                  fontSize: 16,
                                  textIndent: '26px',
                                  color: '#FF5353',
                                  paddingTop: 18,
                                  // marginLeft: 22,
                                  marginTop: -26,
                                  position: "absolute"
                                }}
                              >Zip/Postal code is required
                              </span>}
                            </Grid>
                          </Grid>

                        </div>

                        <div className=''>
                          <Grid container style={{ paddingTop: 40 }}>

                            <Grid lg={6} style={{ paddingRight: 20 }}>
                              <div className='form-group input-group'>
                                <span className='border-lable-flt'>
                                  <input type='text' value={this.state.email}
                                    onBlur={(e) => {
                                      const emailRegex = /^([a-z\d\.-]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/
                                      if (e.target.value.match(emailRegex)) {
                                        this.setState({ isEmailError: false })
                                      }
                                      else {
                                        this.setState({ isEmailError: true })
                                      }
                                      if (email.length === 0) {
                                        this.setState({ isEmailError: false })
                                      }
                                    }}
                                    onChange={(e) => {
                                      this.setState({ email: e.target.value })
                                    }}
                                    // onFocus={(e) => this.setState({ isEmailError: false })}
                                    className={`form-control ${isEmailError && 'v_error'}`} id='label-name_3' placeholder='Email Address' required />
                                  <label for='label-name_3'>Email Address</label>
                                </span>

                              </div>
                              {isEmailError && <span className="tel_error_msg"
                                style={{
                                  display: 'flex',
                                  fontFamily: 'Roboto-Regular',
                                  letterSpacing: 0.4,
                                  color: 'red !important',
                                  fontSize: 16,
                                  textIndent: '26px',
                                  color: '#FF5353',
                                  paddingTop: 18,

                                  marginTop: -26,
                                  position: "absolute"
                                }}>Invalid email</span>}
                            </Grid>

                            <Grid lg={6} className='phoneInput'>
                              <PhoneInput
                                inputProps={{
                                  name: 'Phone Number',

                                }}
                                autocomplete="new-password"
                                onBlur={() => {
                                  if (phoneNumber.length > this.state.dialCode.length + 1) {
                                    if (countryAlphaCode === 'us') {
                                      if (phoneNumber && phoneNumber.length !== 12) {
                                        this.setState({ phoneError: 'Invalid phone number', error: true })
                                      } else {
                                        this.setState({ phoneError: false, error: false, isPhonenumberError: false })
                                      }
                                    }

                                    if (countryAlphaCode === 'ca') {
                                      if (phoneNumber && phoneNumber.length !== 12) {
                                        this.setState({ phoneError: 'Invalid phone number', error: true })
                                      } else {
                                        this.setState({ phoneError: false, error: false, isPhonenumberError: false })
                                      }
                                    }

                                    if (countryAlphaCode === 'my') {
                                      if (phoneNumber && phoneNumber.length !== 13) {
                                        this.setState({ phoneError: 'Invalid phone number', error: true })
                                      } else {
                                        this.setState({ phoneError: false, error: false, isPhonenumberError: false })
                                      }
                                    }

                                    if (countryAlphaCode === 'gb') {
                                      if (phoneNumber && phoneNumber.length !== 13) {
                                        this.setState({ phoneError: 'Invalid phone number', error: true })
                                      } else {
                                        this.setState({ phoneError: false, error: false, isPhonenumberError: false })
                                      }
                                    }
                                  }
                                }}

                                variant='outlined'

                                onlyCountries={['us', 'ca', 'gb', 'my']}
                                value={this.state.dialCode}
                                country={'us'}
                                onKeyDown={e => this.setState({ num: e.target.value })}
                                onFocus={() => {
                              this.setState({ error: false, phoneError: '', isPhonenumberError: false })
                                }}
                                onChange={(phoneNumber, data, e, formattedValue) => {
                                  this.setState({ phoneNumber: `+${phoneNumber}`, countryAlphaCode: data.countryCode, dialCode: data.dialCode })
                                  if (isPhonenumberError === true || error === true) {
                                    if (data.countryCode === 'us') {
                                      if (phoneNumber && phoneNumber.length !== 11) {
                                        this.setState({ phoneError: 'Invalid phone number', error: true })
                                      } else {
                                        this.setState({ phoneError: false, error: false, isPhonenumberError: false })
                                      }
                                    }

                                    if (data.countryCode === 'ca') {
                                      if (phoneNumber && phoneNumber.length !== 11) {
                                        this.setState({ phoneError: 'Invalid phone number', error: true })
                                      } else {
                                        this.setState({ phoneError: false, error: false, isPhonenumberError: false })
                                      }
                                    }

                                    if (data.countryCode === 'my') {
                                      if (phoneNumber && phoneNumber.length !== 12) {
                                        this.setState({ phoneError: 'Invalid phone number', error: true })
                                      } else {
                                        this.setState({ phoneError: false, error: false, isPhonenumberError: false })
                                      }
                                    }

                                    if (data.countryCode === 'gb') {
                                      if (phoneNumber && phoneNumber.length !== 12) {
                                        this.setState({ phoneError: 'Invalid phone number', error: true })
                                      } else {
                                        this.setState({ phoneError: false, error: false, isPhonenumberError: false })
                                      }
                                    }
                                  }



                                  if (this.state.dialCode !== data.dialCode) {
                                    this.setState({ error: false, phoneError: '', isPhonenumberError: false, phoneNumber: data.dialCode , isfocus : true })
                                  }
                                  else {
                                    this.setState({isfocus : false })
                                  }

                                }}

                                containerStyle={{ width: '100%', height: '50px' }}
                                buttonStyle={{ width: '50px', height: '50px', borderRadius: '3px' }}
                                inputStyle={this.state.error ? {
                                  marginLeft: '0px', height: '70px', width: '100%', fontFamily:
                                    'Roboto-Regular', fontSize: '24px', border: '2px solid red'
                                } : { marginLeft: '0px', height: '70px', width: '100%', fontFamily: 'Roboto-Regular', fontSize: '24px' }}
                                containerClass={(this.state.error) && 'phoneErrorshow'}

                              />
                              {phoneError && <span className="tel_error_msg"
                                style={{
                                  display: 'flex',
                                  fontFamily: 'Roboto-Regular',
                                  letterSpacing: 0.4,
                                  color: 'red !important',
                                  fontSize: 16,
                                  textIndent: '5px',
                                  color: '#FF5353',
                                  paddingTop: 0,
                                  marginLeft: 11,
                                  marginTop: 24,
                                  position: "absolute"
                                }}
                              >{phoneError}
                              </span>}
                            </Grid>
                          </Grid>
                        </div>

                        <div className='row1'>
                          <Grid container style={{ paddingTop: 40 }}>
                            <Grid lg={12}>

                              <div className='form-group input-group bmd-form-group'>
                                <select className='browser-default custom-select ' onChange={(e) => this.setState({ inquiryType: e.target.value })}>
                                  <option selected='Fund' value=''> Inquiry Types</option>
                                  <option value='Zakat'>Zakat</option>
                                  <option value='Fund'>Fund</option>
                                  <option value='Sadaqa'>Sadaqa</option>
                                </select>
                              </div>

                            </Grid>
                          </Grid>

                        </div>

                        <div className='row1'>
                          <Grid container style={{ paddingTop: 40 }}>
                            <Grid lg={12}>

                              <div className='form-group input-group bmd-form-group'>
                                <span className='border-lable-flt'>
                                <textarea className='text_area form-control' value={message}
                                  onChange={(e) => {
                                    if (e.target.value.length <= 200) {
                                    this.setState({ message: e.target.value })
                                    }
                                  }}
                                   maxlength='300' minlength='40' id='label-name' placeholder='Write Your Message....' required />
                                  <label for='label-name'>Write Your Message....</label>
                                <p className='text_limit_hint'>CHAR {message.length}/200</p>
                                </span>
                              </div>

                            </Grid>
                          </Grid>
                        </div>
                        <div className='btn_message'>
                          {this.state.isSubmit ? <button onClick={(e) => this.submitForm(e)}
                            type='button' className='proceed-btn btn_blue'
                          >Send message
                          </button> : <CircularProgress color='primary' />}
                        </div>

                      </form>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div> :
              <div className='messageBox'>
                <div className='section'>
                  <img src={imgtick} width='108px' height='108px' />
                </div>
                <div className='messageheading'>
                  <h1>Sent Successfully</h1>
                  <p>Your message was sent successfully. One of our team members will reach you shortly</p>
                </div>
                <div
                className='gohomeBtn'
                onClick={()=>{
                      this.props.history.replace('/home')
                }}>
                  <span><img src={require('../../assets/rawimg/home-24px.png')} /></span>
                  <span className='gohometext'>Home</span>
                  <span className='gohometext'></span>
                </div>
              </div>}
          </div>

      </div>
    )
  }

}
function mapStateToProps(state) {
  return {

    orgId: state.users.orgId,

  }
}



export default connect(mapStateToProps, null)(withRouter(Contact))