export function updateUser() {
  return dispatch => dispatch({ type: 'SET_USER', payload: 'Ali' })
}

export function updateUserAsGuest(userAsGuest, phoneNumber) {
  return dispatch => dispatch({ type: 'SET_USER_AS_Guest', payload: userAsGuest, phoneNo: phoneNumber })
}
export function updateFirstName(firstName) {
  return dispatch => dispatch({ type: 'SET_FIRST_NAME', firstName: firstName })
}
export function updateLastName(lastName) {
  return dispatch => dispatch({ type: 'SET_LAST_NAME', lastName: lastName })
}

export function updateEmail(email) {
  return dispatch => dispatch({ type: 'SET_EMAIL', email: email })
}

export function updateErrorFlag(errorFlag) {
  return dispatch => dispatch({ type: 'SET_ERROR', errorFlag: errorFlag })
}
export function updateuserToken(token) {
  return dispatch => dispatch({ type: 'SET_TOKEN', token: token })
}
export function orgId(orgId) {
  return dispatch => dispatch({ type: 'SET_ID', orgId: orgId })
}
export function orgData(orgdata) {
  return dispatch => dispatch({ type: 'SET_ORGANIZATION', orgdata: orgdata })
}
export function isHeader(showHeader) {
  return dispatch => dispatch({ type: 'SET_HEADER', showHeader: showHeader })
}

export function updateSalah(salah) {
  return dispatch => dispatch({ type: 'SET_SALAH', salah: salah })
}
export function updateReminders(reminders) {
  return dispatch => dispatch({ type: 'SET_REMINDERS', reminders: reminders })
}
export function updateEvents(events) {
  return dispatch => dispatch({ type: 'SET_EVENTS', events: events })
}
export function updateAnnoucements(annoucements) {
  return dispatch => dispatch({ type: 'SET_ANNOUCEMENTS', annoucements: annoucements })
}
export function updateServices(services) {
  return dispatch => dispatch({ type: 'SET_SERVICES', services: services })
}
export function clearUserFields() {
  return dispatch => dispatch({ type: 'SET_CLEAR_FEILDS' })
}


