import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import moment from 'moment'
import { connect } from 'react-redux'
import Countdown from '../../components/countdown/timer'
import Button from '../../components/Button'
import HomeRoundedIcon from '@material-ui/icons/HomeRounded'
import './style.css'
import { useSelector } from "react-redux";
function Events(props) {
  const events = useSelector(state => state.users.events);
  if (!events) {
    props.history.goBack()
  }
  const eventsdata = events?.filter((item) => item.published)
  return (
    <div className='bg'>
      <div className='kiosk_container'>
        <Grid container spacing={0}>

          <Grid item lg={2}>
            <div className='btn_back_innrepage'>
              <Link className='linkStyle' to='/home'>
                <Button
                  title='GO BACK'
                  style={{ fontSize: '22px', backgroundColor: '#fff', color: '#7266FB', fontFamily: 'Roboto-Medium', }}
                  widthHeight={[270, 70]}
                  variant='outlined'
                  icon={<ArrowBackIcon />}
                />
              </Link>
            </div>
          </Grid>
          <Grid item lg={10} style={{ paddingTop: 0 }}>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }} className="event_col">
              {eventsdata && eventsdata.map((item, index) => (
                <Grid key={index} item lg={5} style={index === 0 ? { paddingTop: 0, maxWidth: '49.2%', flexBasis: '49.2%', } : index === 1 ? { paddingTop: 0, maxWidth: '49.2%', flexBasis: '49.2%', marginTop: '40px' } : { paddingTop: 0, maxWidth: '49.2%', flexBasis: '49.2%', marginTop: '25px' }}>
                  <div className='event_wrapper'>
                    {index === 0 && <div className='page_link'>
                      <Link to='/home'>{<HomeRoundedIcon />} Home</Link>
                      <span><i className='fas fa-chevron-right' /></span>
                      <Link to='/events'>EVENTS</Link>
                    </div>}
                    <div className='evn_container box_shadow'>

                      <div className={`env_left_col ${!item?.image?.src && 'pattren_2'}`} style={item && item.image && item.image.src ? { backgroundImage: `url(${item.image.src})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center' } : {}}>
                        <Countdown
                          timeTillDate={item.endAt}
                        />
                      </div>
                      <div className='env_right_col'>
                        <h1 className='event_card_title'>{item.title}</h1>
                        <div className='event_list'>
                          <li><img src={require('../../assets/rawimg/time_icon.png')} />{moment(item.startAt).format('dddd D, YYYY, hh:mm A')}</li>
                          <li><img src={require('../../assets/rawimg/location_icon.png')} />
                            <span>{`${item?.location?.address?.line1} ${item?.location?.address?.line2},`}</span>
                            <br />
                            <span className='secondLine'>{`${item?.location?.address?.city}, ${item?.location?.address?.state} ${item?.location?.address?.postal_code}`}</span>
                          </li>
                          <li><img src={require('../../assets/rawimg/food_icon.png')} /> <span className='foodlist'>{item.refreshment.providedList.map(i => i + ', ')}</span></li>
                          {item.entry.entryType === 'public' && item.entry.feeRequired === 'free' &&
                            <li><img src={require('../../assets/rawimg/receipt_icon.png')} />FREE</li>
                          }
                          {item.entry.entryType === 'public' && item.entry.feeRequired === 'required' &&
                            <li><img src={require('../../assets/rawimg/receipt_icon.png')} />${item.entry.fee.toFixed(2)} / PERSON</li>
                          }
                        </div>

                        <div className='view_all'><Link to={{
                          pathname: '/event-details',
                          state: {
                            eventdetailData: item
                          }
                        }}>SEE DETAILS <i className='fas fa-chevron-right icon' /></Link></div>
                      </div>
                      <div className='clearfix' />
                    </div>
                  </div>
                </Grid>
              ))}
            </div>
          </Grid>
        </Grid>
      </div>
    </div>

  )
}

function mapStateToProps(state) {
  return {
    isHideButton: state.payment.isHideButton,
    events: state.users.events
  }
}


export default connect(mapStateToProps, null)(Events)
