import React, { Component } from 'react'
import './style.css'
import { connect } from 'react-redux'
import moment from 'moment'
import Moment from 'moment-timezone'
import { withRouter } from 'react-router-dom'

import LoginCard from '../LoginCard'
class SalahTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      Salahtable: [],
    }
  }

  componentDidMount() {
    const { salah } = this.props;
    if(salah){
    const filterSalah = salah.filter((item)=> item.active)
    if (filterSalah) {
      console.log('filterSalah',filterSalah)
      const sortedSalahs = filterSalah.slice().sort((a, b) => this.getTimeStampclock(a.waqt,a.timezoneFormat) - this.getTimeStampclock(b.waqt,b.timezoneFormat))
      const updatedAt = filterSalah.sort((a, b) => this.getTimeStamp(b.updatedAt) - this.getTimeStamp(a.updatedAt))
      this.setState({ Salahtable: sortedSalahs, updatedAt: updatedAt[0]?.updatedAt })
    }
  }
  else {
     this.props.history.push('home')
  }
  }

  getTimeStampclock(time,timezoneFormat) {
    let i =  Moment(time).tz(timezoneFormat).format("HH:mm")
    return parseInt(i)
  }
  getTimeStamp(time) {
    let date = moment(time, 'MMM DD,YYYY')
    let timestamp = moment(date).format("x");
    return timestamp
  }
  render() {
    const { Salahtable, updatedAt,  } = this.state
    return (
      <>
        <div className='slah_table'>
          <table className='table'>
            <thead className='thead-dark'>
              <tr>
                <th style={{ display: 'flex' }}>Salah Name</th>
                <th>Waqt</th>
                <th style={{ textAlign: 'right' }}>Iqamah Time</th>
              </tr>
            </thead>
            <tbody>
              {Salahtable && Salahtable.sort((a, b) => a.order - b.order).map((item, index) => {
                return <tr key={index}>
                  <td className='slah_name'>
                    {item.salahName.split(' (')[0]} <span className='salahList'>{`(${item.salahName.match(/\(([^)]+)\)/)[1]})`}</span>
                  </td>
                  <td className='wakt_time'>{item.waqt ? Moment(item.waqt).format('hh:mm A') : '-'}</td>
                  <td className='iqama_time'>{item.iqamahTime && Moment(item.iqamahTime).format("hh:mm A")}</td>
                </tr>
              })}
            </tbody>
          </table>
          <p className='Recent-update'>Recent Update - {Moment(updatedAt).format("MMMM DD, YYYY")}</p>
        </div>
      </>
    )
  }
}
function mapStateToProps(state) {
  return {

    salah: state.users.salah,

  }
}



export default connect(mapStateToProps, null)(withRouter(SalahTable))