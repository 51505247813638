import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import RadioButtonUncheckedRoundedIcon from '@material-ui/icons/RadioButtonUncheckedRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import LockIcon from '@material-ui/icons/Lock'
import './css/DonationCategories.css'

import ModalComp from './Modal';

import DonationPackage from './DonationPackage';


function DonationCategories(props) {
    const [value, setValue] = React.useState(2);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const shoot = () => {
        alert("Onclick is working fine!");
    }

    const tabData = [
        {
            label: "General Donation",
            onClick: shoot,
        },

        {
            label: "New construction for new school",
            onClick: shoot,
        },

        {
            label: "Sadaqah",
            onClick: shoot,
        },

        {
            label: "Weekend Class",
            onClick: shoot,
        },

        {
            label: "Kaffara",
            onClick: shoot,
        },

        {
            label: "Zakat",
            onClick: shoot,
        },

        {
            label: "Sadaqah",
            onClick: shoot,
        },

        {
            label: "Weekend Class",
            onClick: shoot,
        },
    ]

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };


    return (

        <div className='DonationCategoriesRwapper'>

            <div className='DonationCategories'>
                <Tabs
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChange}
                    aria-label="disabled tabs example"
                >
                    {tabData.map((item, i) => (
                        <Tab icon={<div className='catTabIcon'>
                            <RadioButtonUncheckedRoundedIcon className='icon-inactive' />
                            <CheckCircleRoundedIcon className='icon-active' />
                            <label>{item.label}</label></div>}
                            onClick={item.onClick}
                        />
                    ))}
                </Tabs>
            </div>

            <div className="ProceedBtn">
                <Button onClick={handleOpen}><LockIcon /> PROCEED NEXT</Button>
            </div>




            <ModalComp
                open={open}
                Title="New construction for new school"
                ModalBody={
                    <div>
                        <DonationPackage />
                    </div>
                }
            />

        </div>

    );
}


export default DonationCategories;