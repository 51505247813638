import React, { Component } from 'react'
import { Button, Input } from '../../components'
import LockIcon from '@material-ui/icons/Lock'
export default class Signup extends Component {
  constructor (props) {
    super(props)
    this.state = {
      fullName: '',
      email: '',
      password: ''
    }

    this.signUP = this.signUP.bind(this)
  }

  signUP () {
    const { fullName, email, password } = this.state

    if (fullName && email && password) {
    } else {

    }
  }

  render () {
    const { fullName, email, password } = this.state

    return (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column'
          }}
        >
          <>
            <div class='right_col_title'>
              <b>Sign Up</b> To Donate
            </div>

            <div style={{ width: '73%', alignSelf: 'center' }}>
              <Input
                label='Full Name'
                type='text'
                value={fullName}
                helperText='invalid email'
                onChange={(e) => { this.setState({ fullName: e.target.value }) }}
              />

              <Input
                value={email}
                label='Email'
                type='text'
                helperText='Helper text'
                onChange={(e) => { this.setState({ email: e.target.value }) }}
              />

              <Input
                label=' Enter Password'
                type='password'
                helperText='Helper text'
                value={password}
                onChange={(e) => { this.setState({ password: e.target.value }) }}
              />

              <p
                style={{
                  color: '#818E94',
                  lineHeight: '20px',
                  marginLeft: '8px'
                }}
              >
                By sign up, you confirm that you've accepted our{' '}
                <b>Terms & Conditions</b> and <b>Privacy policy</b>
              </p>

              <Button
                title='Create Account'
                widthHeight={[510, 60]}
                icon={<LockIcon />}
                onClick={() => this.signUP()}
              />

              <center>
                <p
                  style={{
                    color: '#818E94',
                    lineHeight: '20px',
                    fontFamily: 'Roboto-Bold'
                  }}
                >
                  {' '}
                  Alerdy have DEENFUND account?{' '}
                  <span
                    onClick={() => this.props.onClick()}
                    style={{ color: '#05CE78', cursor: 'pointer' }}
                  >
                    Login
                  </span>
                </p>
              </center>
            </div>
          </>
        </div>
      </>
    )
  }
}
