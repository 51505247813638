import React from "react";
import {
  salah,
  reminders,
  upcomingEvents,
  annoucements,
  services,
  organizations,
} from "../Donation/apis/index";
import { connect } from "react-redux";
import {
  updateSalah,
  updateReminders,
  updateEvents,
  updateAnnoucements,
  updateServices,
  updateuserToken,
  orgData,
  orgId,
} from "../../store/users/actions";
import CircularProgress from "@material-ui/core/CircularProgress";

const withContext = (Component) => {
  class Context extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        salahResponse: [],
        dailyReminders: [],
        eventResponse: [],
        orgId: "",
        token: "",
        loader: "",
      };
    }
    async componentDidMount() {
      console.log('context')
      const orgId = this.props.orgId;
      const token = this.props.token;
      if (orgId && token) {
        this.setState({ loader: true }, async () => {
          const getOrganization = async () => {
            try {
              const res = await organizations(orgId, token);
              this.props.orgData(res.data.body.data);
            } catch (e) {
              //("====>", e);
            }
          };
          const getSalah = async () => {
            try {
              let salahRes = await salah(orgId, token);
              this.props.updateSalah(salahRes.data.body.data);
            } catch (e) {
              this.props.updateSalah([]);
            }
          };
          const getReminder = async () => {
            try {
              let remRes = await reminders(orgId, token);
              this.props.updateReminders(remRes.data.body.data);
            } catch (e) {
              this.props.updateReminders([]);
            }
          };

          const getEvents = async () => {
            try {
              let eventRes = await upcomingEvents(token);
              this.props.updateEvents(eventRes.data.body.data);
            } catch (e) {
              this.props.updateEvents([]);
            }
          };
          const getAnnouncements = async () => {
            try {
              let anncRes = await annoucements(orgId, token);
              this.props.updateAnnoucements(anncRes.data.body.data);
            } catch (e) {
              this.props.updateAnnoucements([]);
            }
          };
          const getServices = async () => {
            try {
              let servicesRes = await services(orgId, token);
              this.props.updateServices(servicesRes.data.body.data);
            } catch (e) {
              this.props.updateServices([]);
            }
          };

          const callDataInOrder = async () => {
            getSalah();
            getReminder();
            getAnnouncements();
            getEvents();
            getServices();
            getOrganization();
            this.setState({ loader: false });
          };

          await callDataInOrder();


        });
      }
    }

    componentWillUnmount() {
      this.setState({ loader: false });
    }
    render() {
      return (
        <>
          {this.state.loader ? (
            <CircularProgress color="primary" className="apiLoader" />
          ) : (
              <React.Fragment>
                <Component {...this.props} />
              </React.Fragment>
            )}
        </>
      );
    }
  }

  function mapStateToProps(state) {
    return {
      orgId: state.users.orgId,
      token: state.users.token,
    };
  }
  const mapDispatchToProps = (dispatch) => {
    return {
      updateSalah: (salahdata) => dispatch(updateSalah(salahdata)),
      updateReminders: (remindersdata) =>
        dispatch(updateReminders(remindersdata)),
      updateEvents: (eventsdata) => dispatch(updateEvents(eventsdata)),
      updateAnnoucements: (annoucementdata) =>
        dispatch(updateAnnoucements(annoucementdata)),
      updateServices: (servicesdata) => dispatch(updateServices(servicesdata)),
      updateuserToken: (token) => dispatch(updateuserToken(token)),
      orgData: (orgdata) => dispatch(orgData(orgdata)),
    };
  }
  return connect(mapStateToProps, mapDispatchToProps)(Context);
};

export default withContext;
