const INITIAL_STATE = {
  userAsGuest: '',
  phoneNo: '',
  firstName: '',
  lastName: '',
  email: '',
  errorFlag: false,
  token: '',
  orgId: '',
  orgdata: [],
  showHeader: false,
  salah: [],
  reminders: [],
  events: [],
  annoucements: [],
  services : []
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_USER': {
      return { ...state, userName: action.payload }
    }
    case 'SET_USER_AS_Guest': {
      return { ...state, userAsGuest: action.payload, phoneNo: action.phoneNo }
    }
    case 'SET_FIRST_NAME': {
      return { ...state, firstName: action.firstName }
    }
    case 'SET_LAST_NAME': {
      return { ...state, lastName: action.lastName }
    }
    case 'SET_EMAIL': {
      return { ...state, email: action.email }
    }
    case 'SET_ERROR': {
      return { ...state, errorFlag: action.errorFlag }
    }
    case 'SET_TOKEN': {
      return { ...state, token: action.token }
    }
    case 'SET_ID': {
      return { ...state, orgId: action.orgId }
    }
    case 'SET_HEADER': {
      return { ...state, showHeader: action.showHeader }
    }
    case 'SET_ORGANIZATION': {
      return { ...state, orgdata: action.orgdata }
    }
    case 'SET_SALAH': {
      return { ...state, salah: action.salah }
    }
    case 'SET_REMINDERS': {
      return { ...state, reminders: action.reminders }
    }
    case 'SET_EVENTS': {
      return { ...state, events: action.events }
    }
    case 'SET_ANNOUCEMENTS': {
      return { ...state, annoucements: action.annoucements }
    }
    case 'SET_SERVICES': {
      return { ...state, services: action.services }
    }
    case 'SET_CLEAR_FEILDS' : {
      return {...state, firstName : '' , lastName : '' , email : '' , phoneNo : ''}
    }
    default:
      return state
  }
}
