import React, { Component } from 'react'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/js/dist/carousel'
import 'bootstrap/dist/js/bootstrap.bundle'
import $ from 'jquery'

import Grid from '@material-ui/core/Grid'

import { DonationIndex, HomePageCards } from '../../components'
import './style.css'
import { annoucements } from '../../components/Donation/apis/index'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      annoucements: [],
      boolean : true
    }
  }


  componentDidMount() {
    this.slider()
  }
  slider = () => {
    $('#myCarousel').carousel({
      interval: 6000,
      pause: 'false'
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.annoucements?.length !== this.props.annoucements?.length) {
        this.setState({boolean : false}, () => {
          this.setState({boolean : true})
        })
    }
  }
  render() {
    const { annoucements } = this.props
    const { boolean } = this.state;
    return (
      <div className='main'>
        <div className='kiosk_container'>
          <Grid container spacing={0}>
            <Grid lg={4}>

              <div className='home-container'>
                <div className='home-donation-container'>
                  <DonationIndex homeNav={this.props} steper={false} page='home' ishomepage={true} />
                </div>
              </div>
            </Grid>

            <Grid lg={8}>

              <div className='home_right_col'>

                <div className='announcement_section'>
                  <img className='anc_icon' src={require('../../assets/rawimg/annoucement_icon.png')} />
                  <div id='demo' className='carousel slide position-relative announcement_slider' data-ride='carousel'>

                  {boolean && <div className='carousel-inner'>
                      {annoucements && annoucements.length > 0 && annoucements.filter((item) => item.active).map((item, index) => <div key={index} className={`carousel-item ${index === 0 && 'active'} `}>
                        <h1>{item.title.slice(0, 34)}</h1>
                      </div>
                      )}

                    </div>}

                    <div className='slider_arrow'>
                      <a className='prv_arrow' href='#demo' data-slide='prev'><i className='fas fa-chevron-left' /></a> <a className='next_arrow' href='#demo' data-slide='next'><i className='fas fa-chevron-left' /></a>
                    </div>
                  </div>
                </div>

                <HomePageCards />

              </div>

            </Grid>
          </Grid>

        </div>
        <div className='clearfix' />


      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    orgId: state.users.orgId,
    token: state.users.token,
    annoucements: state.users.annoucements
  }
}



export default connect(mapStateToProps, null)(withRouter(Home))