import React from 'react'

import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import moment from 'moment'
import Countdown from '../../components/countdown/timer'
import HomeRoundedIcon from '@material-ui/icons/HomeRounded'
import { withRouter } from 'react-router-dom'

// import Button from '../../components/Button'
import Button from '../../components/Button'
import { useSelector } from "react-redux";
import { connect } from 'react-redux'
import '../Events/style.css'
import './style.css'

function EventDetails(props) {
  let _id = props.location.state.eventdetailData._id
  const events = useSelector(state => state.users.events);
  if (!events) {
    props.history.push("home")
    return null
  }
  const filterEvent = events.filter(item => item._id === _id )
  console.log('filterEvent',filterEvent)
  let att = [filterEvent?.[0]?.attendee]
  let customProfession = filterEvent?.[0]?.attendee?.customProfession
  let attendees = att.map((item, index) => {
    if (item.otherItemList.all === true) {
      return 'Open to anyone'
    }
    else {
      let newKeys = Object.keys(item) // agegroup gender otherItemList
      let agegroup = item[newKeys[0]]
      let gender = item[newKeys[1]]
      let otherItemList = item[newKeys[2]]
      let ageGroupArray = Object.keys(agegroup).filter((e) => agegroup[e])
      let genderArray = Object.keys(gender).filter((e) => gender[e])
      let otherArray = Object.keys(otherItemList).filter((e) => otherItemList[e])

      return genderArray.concat(ageGroupArray).concat(otherArray)
    }
  })
  console.log('attendees',attendees , customProfession)
  attendees = [...attendees[0],...customProfession]
  console.log('attendees after',attendees)

  return (
    <div className='bg'>
     <div className='kiosk_container'>

        <Grid container spacing={3}>
          <Grid item lg={2}>
          <div className='btn_back_innrepage'>
            <span className='linkStyle'>
              <Button
                title='GO BACK'
                style={{ fontSize: '22px', backgroundColor: '#fff', color: '#7266FB', fontFamily: 'Roboto-Medium',}}
                widthHeight={[270, 70]}
                variant='outlined'
                icon={<ArrowBackIcon />}
                onClick={()=> props.history.push("events")}
              />
            </span>
            <Link className='linkStyle' to='/home'>
              <Button
                title='HOME'
                style={{ fontSize: '22px', backgroundColor: '#fff', color: '#7266FB', fontFamily: 'Roboto-Medium',}}
                widthHeight={[270, 70]}
                variant='outlined'
                icon={<HomeRoundedIcon />}
              />
            </Link>
            </div>
          </Grid>

          <Grid item lg={3}>
            <div className='event_wrapper'>
              <div className='page_link'>
                <Link to='/home'>{<HomeRoundedIcon />} Home</Link>
                <span><i className='fas fa-chevron-right' /></span>
                <Link to='/events'>Events</Link>
                <span><i className='fas fa-chevron-right' /></span>
                <Link className="page_active" to='/event-details'>EVENT DETAILS</Link>
              </div>

              <div className={`evn_container box_shadow event_details ${filterEvent?.[0] && filterEvent?.[0]?.image && filterEvent?.[0]?.image.src ? 'event_showbg' : 'event_hidebg'}`} style={filterEvent?.[0]?.image?.src ? { backgroundImage: `url(${filterEvent?.[0]?.image.src})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center' } : {}}>

              {filterEvent?.[0]?.entry.entryType === 'public' && filterEvent?.[0]?.entry.feeRequired === true && <div className='event_amount'>${filterEvent?.[0]?.entry.fee.toFixed(2)} / PERSON</div>}
                {filterEvent?.[0]?.entry.entryType === 'public' && filterEvent?.[0]?.entry.feeRequired === false && <div className='event_amount'>FREE</div>}
                <Countdown
                  timeTillDate={filterEvent?.[0]?.startAt}
                />
                <div className='clearfix' />
              </div>
            </div>
          </Grid>

          <Grid item lg={6}>
            <div className='evn_container'>

              <div className='event_info'>
                <h1>{filterEvent?.[0]?.title}</h1>

                <div className='info_list'>

                  <li>
                    <div className=''>
                      <Grid container spacing={3}>

                        <Grid item lg={6}>
                          <p>Start Date</p>
                          <h2>{moment(filterEvent?.[0]?.startAt).format('dddd D, YYYY, hh:mm A')}</h2>
                        </Grid>

                        <Grid item lg={0} className='border_line' />

                        <Grid item lg={5}>
                          <p>End Date</p>
                          <h2>{moment(filterEvent?.[0]?.endAt).format('dddd D, YYYY, hh:mm A')}</h2>
                        </Grid>
                      </Grid>
                    </div>
                  </li>

                  <li>
                    <p>Location</p>
                    <h2> <span>{`${filterEvent?.[0]?.location?.address?.line1} ${filterEvent?.[0]?.location?.address?.line2},`}</span>
                           <br/>
                           <span>{`${filterEvent?.[0]?.location?.address?.city}, ${filterEvent?.[0]?.location?.address?.state} ${filterEvent?.[0]?.location?.address?.postal_code}`}</span></h2>
                  </li>

                  <li>
                    <p>Food</p>
                    <h2>{filterEvent?.[0]?.refreshment.providedList}</h2>
                  </li>

                  <li>
                    <p>Attendees</p>
                    <h2>
                      {attendees.map((item) => {
                        return item.charAt(0).toUpperCase() + item.slice(1) + (item != attendees[attendees.length - 1] ? ',' + ' ' :  '' )

                      })}
                    </h2>
                  </li>

                  <li>
                    <p>Speaker</p>

                    {filterEvent?.[0]?.speakers.map((item, index) => {
                      return <div key={index} className='pill'><img src={item.image} /> <span>{item.name}</span></div>
                    })}
                  </li>

                </div>

              </div>

              <div className='clearfix' />
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
function mapStateToProps(state) {
  return {
    isHideButton: state.payment.isHideButton,
    events: state.users.events
  }
}
export default connect(mapStateToProps, null)(EventDetails)