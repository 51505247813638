import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import RadioButtonUncheckedRoundedIcon from '@material-ui/icons/RadioButtonUncheckedRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import LockIcon from '@material-ui/icons/Lock'
import './css/DonationCategories.css'


function DonationPackage(props) {

    const [donationAmount, setValue] = React.useState(2);

    const handleChangeAmount = (event, newValue) => {
        setValue(newValue);
    };

    const [donationTime, setValueAmount] = React.useState(2);

    const handleChangeTime = (event, newValue) => {
        setValueAmount(newValue);
    };


    const shoot = () => {
        alert("Onclick is working fine!");
    }

    const tabData = [
        {
            label: "$50",
            onClick: shoot,
        },

        {
            label: "$100",
            onClick: shoot,
        },

        {
            label: "$200",
            onClick: shoot,
        },

        {
            label: "$300",
            onClick: shoot,
        },

        {
            label: "OTHER",
            onClick: shoot,
        },


    ]

    const timePeriod =[
        {
            label: "ONE TIME",
            onClick: shoot,
        },

        {
            label: "MONTHLY",
            onClick: shoot,
        },

        {
            label: "YEARLY",
            onClick: shoot,
        },
    ]


    return (

        <div className='DonationPackageWrapper'>

            <div className='DonationPackage'>
                <Tabs
                    value={donationAmount}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChangeAmount}
                    aria-label="disabled tabs example"
                >
                    {tabData.map((item, i) => (
                        <Tab icon={<div className='catTabIcon'>
                            <RadioButtonUncheckedRoundedIcon className='icon-inactive' />
                            <CheckCircleRoundedIcon className='icon-active' />
                            <label>{item.label}</label></div>}
                            // onClick={item.onClick}
                        />
                    ))}
                </Tabs>
            </div>

            <div className='DonationPackageTime'>
                <Tabs
                    value={donationTime}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChangeTime}
                    aria-label="disabled tabs example"
                >
                    {timePeriod.map((item, i) => (
                        <Tab icon={<div className='catTabIcon'>
                            <RadioButtonUncheckedRoundedIcon className='icon-inactive' />
                            <CheckCircleRoundedIcon className='icon-active' />
                            <label>{item.label}</label></div>}
                            // onClick={item.onClick}
                        />
                    ))}
                </Tabs>
            </div>

            <div className="ProceedBtn">
                <Button><LockIcon /> PROCEED NEXT</Button>
            </div>


        </div>

    );
}


export default DonationPackage;