import React from 'react'
import { Link } from 'react-router-dom'

import Grid from '@material-ui/core/Grid'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import HomeRoundedIcon from '@material-ui/icons/HomeRounded'

import Cards from './cards'

import Button from '../../components/Button'
import './style.css'
import { connect } from 'react-redux'


class Services extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      services : []
    }
  }

  async componentDidMount(){
    const services = this.props.services
    this.setState({ services: services})
  }

  render() {
    const { services } = this.state;
    return (

      <div className='bg'>
        <div className='kiosk_container'>
            <div className=''>

              <Grid container spacing={0} style={{ paddingTop: 0 }}>
                <Grid item lg={2}>
                <div className='btn_back_innrepage'>
                <Link className='linkStyle' to='/home'>
                <Button
                    title='GO BACK'
                    style={{ fontSize: '22px', backgroundColor: '#fff', color: '#7266FB', fontFamily: 'Roboto-Medium',}}
                    widthHeight={[270, 70]}
                    variant='outlined'
                    icon={<ArrowBackIcon />}
                  />
            </Link>
            </div>
                </Grid>
                <Grid item lg={8}>
                  <div className='services'>
                    <div className='page_link'>
                      <Link to='/home'>{<HomeRoundedIcon />} Home</Link>
                      <span><i className='fas fa-chevron-right' /></span>
                      <Link to='/services'>Services</Link>
                    </div>
                  </div>
                  <div className="services_col_container" >
                  {services && services.filter((item => item.featured)).map((item)=> {
                   return <Cards key={item._id} thumbnailImage={item.thumbnailImage} title={item.name} id={item._id} description={item.description} image={item.image}  />
                  })}

                  </div>
                  {/* <Cards image={require('../../assets/rawimg/services_2.png')} title='Ramadan Iftar & Tarawih' />
                  <Cards image={require('../../assets/rawimg/services_3.png')} title='Monthly Family Night' />
                  <Cards image={require('../../assets/rawimg/services_4.png')} title='Weekend & Summer School' />
                  <Cards image={require('../../assets/rawimg/services_1.png')} title="Daily and Jumu'ah Prayers" />
                  <Cards image={require('../../assets/rawimg/services_2.png')} title='Ramadan Iftar & Tarawih' />
                  <Cards image={require('../../assets/rawimg/services_3.png')} title='Monthly Family Night' />
                  <Cards image={require('../../assets/rawimg/services_4.png')} title='Weekend & Summer School' /> */}
                </Grid>
                <Grid item lg={2} style={{ marginTop: 8, marginLeft: 0, paddingRight: 0, }}>
                  <div class='sidebar top_space'>

                    <div class='donation_wgt pattren_3'>
                      <h1>Make a Donation</h1>
                      <p>Your generous donation will help us to continue and add more awesome services insha’Allah</p>

                      <Link class='wgt_readmore' to={{
                        pathname: '/donation',
                        state: {
                          ishomepage: false
                        }
                      }}>let's Donate</Link>

                    </div>
                    <div class='clearfix' />
                  </div>
                  {/* <div className='sidebar top_space'>

                  <div className='donation_wgts pattren_3'>
                    <h2>Make a Donation</h2>
                    <p>Your generous donation will help us to continue and add more awesome services insha’Allah</p>
                    <Link className='wgts_readmore' to='/donation'>let's Donate</Link>
                  </div>

                </div> */}
                </Grid>
              </Grid>
            </div>

        </div>
      </div>

    )
  }
}

function mapStateToProps(state) {
  return {
    orgId: state.users.orgId,
    token: state.users.token,
    services : state.users.services
  }
}



export default connect(mapStateToProps, null)(Services)
