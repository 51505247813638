import React from 'react'
import moment from 'moment';
import Card from './Card'
import './style.css'
import './salahCard.css'
function salahCard(props) {
  const { currentSalah, currentSalahTime, nextSalah, hours, minutes } = props
  return (
    <Card title='Next Salah' showFooter navigateTo='salah'>
      <div className='ev_cont'>
        <div className='tm_hnt'>Time remaining till next salah</div>

        <div className='large_time'>
          <div id='clock'>
            <div className='time_col'>
              <p className='unit' id='hours'>{hours < 10 ? '0' + hours : hours}</p>
              <h2>hours</h2>
            </div>
            <div className='time_col'><span>:</span></div>
            <div className='time_col'>
              <p className='unit' id='minutes'>{minutes < 10 ? '0' + minutes : minutes}</p>
              <h2>minutes</h2>
            </div>

          </div>
        </div>

      </div>

      {/* <div className='slat_time_card'> <span className='slat_name'>{currentSalah && currentSalah.split(' (')[0] }</span>
      <span className='slat_time'>{currentSalahTime && currentSalahTime}</span></div> */}
      <div className='salah-childs'>
        {nextSalah && nextSalah.sort((a, b) => a.order - b.order).slice(0, 5).map((item, index) => {
          return <div className='slat_time_card slat_time_card_bottom' key={index}> <span className='slat_name'>{item.salahName.split(' (')[0]}</span> <span className='slat_time'>{moment(item.salahtime).format("hh:mm A")}</span></div>
        })}
      </div>
    </Card>

  )
}

export default salahCard
